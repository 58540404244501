/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import axios from "axios";
import React, { Component } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { FormattedMessage } from "react-intl";
import BaseLayout from "../../Layouts/BaseLayout";
import PageBanner from "../../Layouts/PageBanner";
import VoteList from "../../Partials/VoteList";
import ContestantDetail from "./ContestantDetail";
import styled from "styled-components";

class Contestant extends Component {
  state = {
    voteList: [],
    missDetail: {},
    time: {},
    voteNumbers: -1,
    smsVoteNumbers: 0,
    momoVoteNumbers: 0,
    socialVoteNumbers: 0,
    totalVote: 0,
    pageNumber: 1,
    pageSize: 10,
    payUrl: "",
    hasMoreItems: true,
    voteListLoading: false,
    showVoteSmsModal: false,
    showVoteMomoModal: false,
    showVoteVinIdModal: false,
    showVoteSocialModal: false,
    showGuessModal: false,
  };

  async componentDidMount() {
    setTimeout(() => {
      const el = document.querySelector("#contestant-detail");
      el && el.scrollIntoView({ behavior: "smooth" });
    }, 1000);

    const script = document.createElement("script");
    script.src = "https://sp.zalo.me/plugins/sdk.js";
    script.async = true;

    document.body.appendChild(script);

    const { missId } = this.props.match.params;

    try {
      const resContestantDetail = await axios.get(
        `/api/get/contestant/${missId}`
      );

      const { missDetail, time } = resContestantDetail.data;

      let {
        voteNumbers,
        smsVoteNumbers,
        momoVoteNumbers,
        socialVoteNumbers,
        totalVote,
      } = missDetail;

      this.setState({
        missDetail,
        voteNumbers,
        smsVoteNumbers,
        momoVoteNumbers,
        socialVoteNumbers,
        totalVote,
        time,
      });

      this.loadMore();
    } catch (error) {
      console.log("xxx Get contestant : ", error);
    }
  }

  loadMore = async () => {
    const { pageNumber, pageSize, voteList, voteNumbers } = this.state;
    const { missId } = this.props.match.params;

    if (voteList.length === voteNumbers) {
      this.setState({ hasMoreItems: false });
      return;
    }

    try {
      // this.setState({ voteListLoading: true });
      const resVoteList = await axios.get(
        `/api/get/contestant-votes/${missId}/${pageNumber}/${pageSize}`
      );

      const newVoteList = [...voteList, ...resVoteList.data.missVoteList];

      if (resVoteList.data.missVoteList.length === 0) {
        this.setState({ hasMoreItems: false });
      }

      this.setState({
        voteList: newVoteList,
        voteListLoading: false,
        pageNumber: this.state.pageNumber + 1,
      });
    } catch (error) {
      this.setState({
        pageNumber: this.state.pageNumber - 1,
        hasMoreItems: false,
      });
      console.log("xxx Get contestant votes : ", error);
    }
  };

  toggleVoteSmsModal = () => {
    this.setState({ showVoteSmsModal: !this.state.showVoteSmsModal });
  };

  toggleVoteMomoModal = async (e) => {
    if (e) {
      if (!e.isTrusted) return;
      const { missId } = this.props.match.params;
      const res = await axios.get(`/api/momo/${missId}`);

      this.setState({ payUrl: res.data.payUrl });
    }

    this.setState({ showVoteMomoModal: !this.state.showVoteMomoModal });
  };

  toggleVoteVinIdModal = async (e) => {
    if (e) {
      if (!e.isTrusted) return;
      const { missId } = this.props.match.params;
      const res = await axios.get(`/api/vin/${missId}`);

      this.setState({ payUrl: res.data.payUrl });
    }

    this.setState({ showVoteMomoModal: !this.state.showVoteMomoModal });
  };

  toggleVoteSocialModal = () => {
    this.setState({ showVoteSocialModal: !this.state.showVoteSocialModal });
  };

  toggleGuessModal = () => {
    this.setState({ showGuessModal: !this.state.showGuessModal });
  };

  render() {
    const { missDetail, time } = this.state;

    const { lang } = this.props;

    const loader = (
      <div className="text-center ajaxLoading">
        <img
          width="200"
          alt="loading ..."
          src="/images/spinner.gif"
          style={{ display: "inline" }}
        />
      </div>
    );

    const contestName =
      lang === "en" ? "Poinsettia Festival English" : "MC NHÍ TOÀN QUỐC 2023";

    return (
      <BaseLayout
        title={
          (missDetail.id &&
            `${missDetail.id} - ${missDetail.name} - ${contestName}`) ||
          contestName
        }
        description={
          (missDetail.id &&
            `${missDetail.id} - ${missDetail.name} - ${contestName}`) ||
          contestName
        }
        setLang={this.props.setLang}
      >
        <main id="main">
          <PageBanner />

          <section className="section-detail">
            <div className="bs-container">
              <div className="bs-row">
                <div className="bs-col">
                  <div className="module module-detail">
                    <div className="module-content">
                      <ContestantDetail time={time} contestant={missDetail} />

                      <div className="detail-history">
                        <Title>
                          <FormattedMessage id="contestant.history" />
                        </Title>

                        <STable className="history-table" id="data-container">
                          <InfiniteScroll
                            dataLength={this.state.voteList.length}
                            next={this.loadMore}
                            scrollThreshold={0.9}
                            hasMore={this.state.hasMoreItems}
                            loader={loader}
                            endMessage={
                              <p style={{ textAlign: "center" }}>
                                <b>
                                  <FormattedMessage id="none" />
                                </b>
                              </p>
                            }
                          >
                            <table className="table">
                              <thead>
                                <tr>
                                  <th>
                                    <FormattedMessage id="contestant.index" />
                                  </th>
                                  <th>
                                    <FormattedMessage id="voterId" />
                                  </th>
                                  <th>
                                    <FormattedMessage id="time" />
                                  </th>
                                </tr>
                              </thead>

                              <tbody>
                                {this.state.voteList.map((el, index) => (
                                  <VoteList
                                    key={el._id}
                                    index={index + 1}
                                    display_account={el.voterId}
                                    create_time={el.createdAt}
                                  />
                                ))}
                              </tbody>
                            </table>
                          </InfiniteScroll>
                        </STable>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </BaseLayout>
    );
  }
}

export default Contestant;

const STable = styled.div`
  border-top: unset !important;
  /* overflow: auto; */
  box-shadow: unset !important;

  .infinite-scroll-component {
    width: 100%;
    overflow: unset !important;
  }
  .table {
    border-collapse: separate;
    border-spacing: 0 20px;
    width: 100%;
    thead {
      th {
        font-family: "Be Vietnam Pro";
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 27px;
        color: #344050;
        text-align: unset;
        align-items: flex-end;
        padding-left: 30px;
        text-transform: capitalize;
        background: unset !important;
        color: #1f2839 !important;
        padding: 10px !important;
      }
    }
  }
  tbody {
    position: relative;
    tr {
      position: relative;
      td {
        background: rgba(9, 121, 229, 0.05);
        border-radius: 8px;
        position: relative;
        font-family: "Be Vietnam Pro" !important;
        font-style: normal;
        font-weight: 500 !important;
        font-size: 16px !important;
        line-height: 150%;
        color: #344050 !important;
        text-transform: capitalize !important;
        position: relative;
        padding: 24px !important;
        a {
          font-family: "Be Vietnam Pro";
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 150%;
          color: #21afff;
        }
      }
      td::after {
        content: "";
        position: absolute;
        display: block;
        width: 30px;
        height: 0px;
        border: 1px dashed #717984;
        transform: rotate(90deg);
        right: -15px;
        top: 35px;
      }
      td:last-child::after {
        display: none;
      }
    }
    tr:nth-child(even) {
      background: #fff5ed !important;
      border-radius: 8px;
    }
  }
`;

export const Title = styled.h2`
  position: relative;
  text-align: center;
  max-width: 317px;
  left: 7.5px;
  top: 0px;
  font-family: "Baloo_2";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 150%;
  color: #153860;
  margin: 0 auto;

  ::before {
    content: "";
    background: none;
    background-image: url("/images/Vector_5.png");
    background-position: top center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 333px;
    height: 56px;
    left: -25px;
    top: 33px;
    position: absolute;
  }
  ::after {
    content: "";
    background: none;
    background-image: url("/images/Vector_6.png");
    background-position: top center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 50px;
    height: 41px;
    left: 291.5px;
    top: 0px;
    position: absolute;
  }
`;
