import React from "react";
import styled from "styled-components";
import { useEffect } from "react";
import { getAvatarUrl } from "../../libs/index";
import { Link } from "react-router-dom";
// {`/images/contestant/avatar/${configId}.jpg`}
export const Item = ({ miss, rank }) => {
  let configId = miss?.code;

  return (
    <Link to={`/thi-sinh/${miss._id}`}>
      <Styles>
        <div className="wrapper">
          <p className="stt">SBD {miss.code}</p>
          <div className="avatar">
            {/* <img src={`/images/contestant/avatar/${configId}.jpg`}/> */}
            <img src={getAvatarUrl(configId)} />
            <span className="rank">
              <p>{rank}</p>
            </span>
          </div>
          <div className="infomation">
            <h4>{miss?.fullname}</h4>
            <p>
              Điểm bình chọn: <span>{miss?.totalPoint}</span>
              {/*<img src="/images/diamond.png"/>*/}
            </p>
          </div>
        </div>
        {/* <div className={`rank-item__box ${rank == 2 ? "top-2" : rank == 3 ? "top-3" : ""}`}>
      <div className="image">
          <span>
             {rank === 1 &&  <img className="king" src={`/images/king.png`}/>}
              <div className="rank-top">
                  <p>1</p>
                  <img src={`/images/rank-top.png`}/>
              </div>
          </span>
          <img className='avatar' src={`/images/contestant/avatar/${configId}.jpg`}/>
         
      </div>
      <div className="stars"><p>{stars} </p><img src="/images/diamond.png"/></div>
      <h6 className="name">{miss?.fullname}</h6>
  </div> */}
      </Styles>
    </Link>
  );
};

const Styles = styled.div`
  padding: 16px;
  border-radius: 8px;
  border: 1px dashed #153860;
  width: 270px;
  margin: 0 -5px;
  transition: 0.2s ease-out;
  position: relative;
  /* overflow:hidden; */

  &:hover {
    background: rgba(0, 0, 0, 0.05);
    box-shadow: -4px 4px 20px #cb8b28;
    transform: scale(1.015) translate(5px, -5px);
    border: 1px solid white;

    &::before {
      top: 8px;
      left: -8px;
    }
  }

  &::before {
    position: absolute;
    content: "";
    border-radius: 8px;
    width: 100%;
    height: 100%;
    top: 0px;
    left: -0px;
    transition: 0.15s linear;
    /* background:rgba(0,0,0,0.05); */
    box-shadow: -2px 2px 3px #cb8b28;
  }

  .wrapper {
    box-sizing: border-box;
    position: relative;
    z-index: 10;

    .stt {
      width: fit-content;
      padding: 2px 10.5px;
      border-radius: 4px;
      background: #cb8b28;
      color: white;
    }

    .avatar {
      /* padding:8px; */
      /* box-sizing: border-box; */
      border: 2px solid #153860;
      border-radius: 50%;
      position: relative;

      img {
        width: 100%;
        border-radius: 50%;
        padding: 7px;
        max-height: 232px;
      }

      .rank {
        position: absolute;
        bottom: 12px;
        right: 12px;
        width: 42px;
        height: 42px;
        border-radius: 50%;
        padding: 3px;
        background: white;

        p {
          background: #cb8b28;
          color: white;
          font-family: Be Vietnam Pro;
          font-size: 20px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0;
          width: 100%;
          height: 100%;
        }
      }
    }

    .infomation {
      h4 {
        font-family: Be Vietnam Pro;
        font-size: 16px;
        font-weight: 700;
        color: #1f2839;
        margin: 14px 0 0 0;
        text-align: center;
      }

      p {
        font-family: Baloo 2;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        margin: 0;

        span {
          font-family: Be Vietnam Pro;
          font-size: 28px;
          font-weight: 700;
          color: #1f2839;
          margin: 0 0 0 4px;
        }

        img {
          padding-bottom: 4px;
          margin-left: 3px;
        }
      }
    }
  }
`;
