export const IMAGE_HOST = "https://cdn.bvote.vn";

export const configId = (missId) => {
  let configId = missId;
  for (let j = 1; j < 1; j++) {
    if (configId && configId.length < 1) {
      configId = "0" + configId;
    }
  }
  return configId;
};

export const getAvatarUrl = (missId) => {
  return `${IMAGE_HOST}/event_mcnhi2023a/AVA/${configId(missId)}.png`;
};

export const getDetailImage = (missId) => {
  return `${IMAGE_HOST}/event_mcnhi2023b/Detail/${configId(missId)}.png`;
};

export const getBannerImage = () => {
  return `${IMAGE_HOST}/event_mcnhi2023b/banner.jpg`;
};

export const getIntroImage = () => {
  return `${IMAGE_HOST}/event_mcnhi2023b/intro.jpg`;
};
