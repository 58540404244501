/* eslint-disable jsx-a11y/anchor-is-valid */
import Axios from "axios";
import React, { useState, useContext, useEffect, useCallback } from "react";
import { FormattedMessage } from "react-intl";
import Button from "../../UI/Button";
import { Modal } from "../../UI/modal";
import { useParams } from "react-router-dom";
import PriceModal from "./modal/PriceModal";
import { VoteMomoModal } from "./modal/VoteMomoModal";
import styled from "styled-components";
import { ContestantsContext } from "../../../App";
import { getDetailImage } from "../../../libs";

const useGetVoteCombo = () => {
  const [comboArr, setComboArr] = useState([]);
  const getVoteCombo = useCallback(async (missId) => {
    const res = await Axios.get("/api/get/vote-combo");
    setComboArr(res?.data || []);
  }, []);

  return { comboArr, getVoteCombo };
};

const ContestantDetail = (props) => {
  const contestant = props.contestant;
  const time = props.time;
  const [payUrl, setPayUrl] = useState("");
  const [combo, setCombo] = useState(0);
  const [isOpen, setOpen] = useState(false);
  const [isOpenPrice, setOpenPrice] = useState(false);

  const { missId, table } = useParams();

  const onHandleVote = async (combo, gateway) => {
    try {
      const res = await Axios.get(`/api/${gateway}/${missId}/${combo}`);
      setPayUrl(res.data.payUrl);
      setCombo(combo);
      setOpen(true);
    } catch (error) {
      console.log("error when get pay Url", error.message);

      setPayUrl("");
      setCombo(combo);
      setOpen(true);
    }
  };

  // const isVoteTime = true;
  const isVoteTime =
    time.now >= time.start && time.now <= time.end ? true : false;

  const { contestants } = useContext(ContestantsContext);

  const totalVote =
    contestants?.find((con) => con.contestantId == missId)?.totalPoint || "0";

  const getInfo = (key) => contestant.info && contestant.info[key];

  const getEmbedLink = (link) => {
    if (link) {
      const id = link.split("watch?v=")[1].split("&list")[0];

      const e = `https://www.youtube.com/embed/${id}`;

      return e;
    }
  };

  const { comboArr, getVoteCombo } = useGetVoteCombo();

  useEffect(() => {
    getVoteCombo();
  }, []);

  return (
    <Styles className="bs-row row-sm-15" id="contestant-detail">
      <div className="bs-col md-55-15">
        <div className="detail-left">
          <div className="video">
            <div className="video-content">
              <video width="100%" height="auto" controls></video>
              <iframe
                src={getEmbedLink(getInfo("link"))}
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
              {/*<img*/}
              {/*  src={getDetailImage(contestant?.code)}*/}
              {/*  alt={contestant.code}*/}
              {/*  style={{ width: "100%" }}*/}
              {/*/>*/}
            </div>
          </div>
        </div>
      </div>
      <div className="bs-col md-45-15">
        <div className="detail-right">
          <div className="slide-detail">
            <div className="slide-item">
              <div className="item">
                <div className="text">
                  <p className="contestant-name">{contestant.fullname}</p>
                  <div className="text-index">
                    <p className="index__desc">
                      <span className="title">Số báo danh:</span>
                      {contestant.code}
                    </p>
                    {/*<p className="index__desc">*/}
                    {/*  <span className="title">Năm sinh:</span>*/}
                    {/*  &nbsp;{getInfo("birth")}*/}
                    {/*</p>*/}
                    {/*<p className="index__desc">*/}
                    {/*  <span className="title">Đến từ:</span>*/}
                    {/*  &nbsp;{getInfo("home")}*/}
                    {/*</p>*/}

                    <p className="index__desc">
                      <span className="title">
                        <FormattedMessage id="votePoints" /> :{" "}
                      </span>
                      {totalVote}
                    </p>
                  </div>
                  <div className="detail-button">
                    <Button
                      className="vote_dropdown open-modal"
                      onClick={() => setOpenPrice(true)}
                      style={{ margin: "0 auto 10px" }}
                      outline
                    >
                      Xem quy đổi điểm bình chọn
                    </Button>

                    {isVoteTime && (
                      <Button
                        className="vote_dropdown"
                        style={{ margin: "0 auto", marginBottom: "10px" }}
                      >
                        Bình chọn qua VNPAY
                        <ul className={`dropdown`}>
                          {comboArr.map((combo, ind) => (
                            <li
                              key={ind}
                              style={{ fontSize: "13px", padding: "5px 8px" }}
                              onClick={() => {
                                onHandleVote(combo.id, "vnpay");
                              }}
                            >
                              {/* {combo} vote{combo > 1 ? "s" : ""} */}
                              {combo.name}
                            </li>
                          ))}
                        </ul>
                      </Button>
                    )}

                    {isVoteTime && (
                      <Button
                        className="vote_dropdown"
                        style={{ margin: "0 auto" }}
                      >
                        Bình chọn qua Momo
                        <ul className={`dropdown`}>
                          {comboArr.map((combo, ind) => (
                            <li
                              key={ind}
                              style={{ fontSize: "13px", padding: "5px 8px" }}
                              onClick={() => {
                                onHandleVote(combo.id, "momo");
                              }}
                            >
                              {/* {combo} vote{combo > 1 ? "s" : ""} */}
                              {combo.name}
                            </li>
                          ))}
                        </ul>
                      </Button>
                    )}

                    {isVoteTime && (
                      <Button
                        className="vote_dropdown"
                        style={{ margin: "0 auto", marginTop: "10px" }}
                      >
                        Bình chọn qua Ngân hàng, thẻ...
                        <ul className={`dropdown`}>
                          {comboArr.map((combo, ind) => (
                            <li
                              key={ind}
                              style={{ fontSize: "13px", padding: "5px 8px" }}
                              onClick={() => {
                                onHandleVote(combo.id, "appota");
                              }}
                            >
                              {/* {combo} vote{combo > 1 ? "s" : ""} */}
                              {combo.name}
                            </li>
                          ))}
                        </ul>
                      </Button>
                    )}

                    {/* <a className="link" onClick={toggleVoteSmsModal}>
                      <FormattedMessage id="contestant.sms" />
                    </a> */}

                    {/* {isVoteTime && round === '10' && (
                        <a
                          className="link"
                          style={{ background: '#B0006D' }}
                          onClick={toggleVoteMomoModal}
                        >
                          <FormattedMessage id="contestant.momo" />
                        </a>
                      )} */}

                    {/* {isVoteTime &&
                        round === process.env.REACT_APP_CURRENT_ROUND && (
                          <a
                            className="link"
                            style={{ background: "#B0006D" }}
                            onClick={toggleVoteMomoModal}
                          >
                            <FormattedMessage id="contestant.momo" />
                          </a>
                        )} */}

                    {/* {isVoteTime &&
                        round === process.env.REACT_APP_CURRENT_ROUND && (
                          <a
                            className="link"
                            style={{ background: "#e60a32" }}
                            onClick={toggleVoteVinIdModal}
                          >
                            <FormattedMessage id="contestant.vinId" />
                          </a>
                        )} */}

                    {/* <a className="link" onClick={toggleVoteSocialModal}>
                      <FormattedMessage id="contestant.social" />
                    </a> */}

                    {/* <a className="link" onClick={toggleGuessModal}>
                      <FormattedMessage id="contestant.guess" />
                    </a> */}

                    {/* <div className="social">
                      <a className="link">
                        <FormattedMessage id="contestant.share" />
                      </a>
                      <ul className="social-list">
                        <li className="social-list__item">
                          <a
                            href={`https://www.facebook.com/sharer/sharer.php?u=${BASE_URL}${pathName}`}
                            className="social-list__link"
                            target="__blank"
                          >
                            <i className="fab fa-facebook" />
                            Facebook
                          </a>
                        </li>

                        <li className="social-list__item">
                          <a
                            href={`https://twitter.com/home?status=${BASE_URL}${pathName}`}
                            className="social-list__link"
                            target="__blank"
                          >
                            <i className="fab fa-twitter-square" />
                            Twitter
                          </a>
                        </li>

                        <li className="social-list__item zalo-button">
                          <a
                            className="zalo-share-button social-list__link"
                            data-href={`${BASE_URL}${pathName}`}
                            target="_blank"
                            data-oaid="579745863508352884"
                            data-layout="2"
                            data-color="white"
                            data-customize="true"
                          >
                            <img
                              alt=""
                              className="ico-zalo"
                              src="https://stc.sp.zdn.vn/share/logo_blue_s.png"
                              style={{ marginBottom: 2 }}
                            />{" "}
                            <i className="fab" />
                            Zalo
                            <iframe
                              title="zalo"
                              frameBorder="0"
                              allowFullScreen=""
                              scrolling="no"
                              width="0px"
                              height="0px"
                              style={{ position: "absolute" }}
                            />
                          </a>
                        </li>
                      </ul>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal isOpen={isOpen} onClose={() => setOpen(false)}>
        <VoteMomoModal
          onClose={() => setOpen(false)}
          combo={combo}
          missId={missId}
          payUrl={payUrl}
        />
      </Modal>

      <div className="modal-container">
        <Modal isOpen={isOpenPrice} onClose={() => setOpenPrice(false)}>
          <PriceModal comboArr={comboArr} onClose={() => setOpenPrice(false)} />
        </Modal>
      </div>
    </Styles>
  );
};

export default ContestantDetail;

const Styles = styled.div`
  .text-index {
    border: 1px dashed #cb8b28 !important;
    border-radius: 12px;
  }

  .contestant-name {
    font-size: 36px;
    font-weight: 700;
    text-transform: capitalize !important;
    margin-bottom: 14px;
  }

  .video-content {
    padding-bottom: 0 !important;
  }

  .index__desc {
    font-size: 18px;
    text-transform: initial !important;
    font-weight: 500;
    .title {
      color: #807b88;
      font-size: 16px;
      font-weight: 400;
      text-transform: initial;
    }
  }
`;
