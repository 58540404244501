import axios from "axios";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { WEB_API } from "../../../config/variables";
import BaseLayout from "../../Layouts/BaseLayout";
import PageBanner from "../../Layouts/PageBanner";

export class BlockDetail extends Component {
  state = {
    currentBlock: {},
    nextBlock: {},
    votesInBlockDetail: [],
  };

  componentDidMount() {
    const { current, next } = this.props.match.params;

    this.fetchData(current, next);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.match.params.current !== this.props.match.params.current &&
      prevProps.match.params.next !== this.props.match.params.next
    ) {
      const { current, next } = this.props.match.params;

      this.fetchData(current, next);
    }
  }

  async fetchData(current, next) {
    try {
      const res = await axios.get(`/api/get/block/${current}/${next}`);

      const { currentBlock, nextBlock, votesInBlockDetail } = res.data;

      this.setState({ currentBlock, nextBlock, votesInBlockDetail });
    } catch (error) {
      console.log("xxx Get block detail : ", error);
    }
  }

  render() {
    const { currentBlock, nextBlock, votesInBlockDetail } = this.state;

    const previousHash =
      currentBlock.number === 1 ? (
        <FormattedMessage id="none" />
      ) : (
        <Link
          to={`/khoi/${parseInt(currentBlock.number) - 1}/${
            parseInt(currentBlock.number) + 1
          }`}
        >
          {currentBlock.previous_hash}
        </Link>
      );

    const nextHash = !nextBlock.data_hash ? (
      <FormattedMessage id="none" />
    ) : (
      <Link
        to={`/khoi/${parseInt(currentBlock.number + 1)}/${
          parseInt(currentBlock.number) + 3
        }`}
      >
        {nextBlock.data_hash}
      </Link>
    );

    const title =
      this.props.lang === "en"
        ? "Block detail - Poinsettia Festival English"
        : "Thông tin khối - MC NHÍ TOÀN QUỐC 2023";

    return (
      <BaseLayout
        title={title}
        description={title}
        setLang={this.props.setLang}
      >
        <main id="main">
          <PageBanner />

          <section className="section-block">
            <div className="bs-container">
              <div className="bs-row">
                <div className="bs-col">
                  <div className="module module-block">
                    <div className="module-content">
                      <div className="vote-content block-content">
                        <p className="title">
                          <span>
                            <FormattedMessage id="block" />:{" "}
                            {currentBlock.number}
                          </span>
                        </p>
                        <div className="content">
                          <p className="desc desc__title">
                            <span>
                              <FormattedMessage id="numberOfVotes" />:{" "}
                              {votesInBlockDetail.length}
                            </span>
                          </p>
                          <p className="desc">
                            <span>
                              <FormattedMessage id="currentHash" />:{" "}
                            </span>
                            {currentBlock.data_hash}
                          </p>
                          <p className="desc">
                            <span>
                              <FormattedMessage id="previousHash" />:{" "}
                            </span>
                            {previousHash}
                          </p>
                          <p className="desc">
                            <span>
                              <FormattedMessage id="nextHash" />:{" "}
                            </span>
                            {nextHash}
                          </p>
                        </div>
                        <div className="table-content" id="data-container2">
                          <table>
                            <thead>
                              <tr>
                                <th>
                                  <FormattedMessage id="voterId" />
                                </th>
                                <th>
                                  <FormattedMessage id="voted" />
                                </th>
                                <th>
                                  <FormattedMessage id="time" />
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {votesInBlockDetail.map((el) => {
                                return (
                                  <tr key={el.txId}>
                                    <td>
                                      <Link
                                        to={`/nguoi-binh-chon/${el.display_account}`}
                                      >
                                        {el.display_account}
                                      </Link>
                                    </td>
                                    <td>{el.contestant_name}</td>
                                    <td>{el.date}</td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </BaseLayout>
    );
  }
}

export default BlockDetail;
