import React, { Component } from "react";
import { FormattedMessage } from "react-intl";

import ContentLayout from "../../Layouts/ContentLayout";
import { INTRO } from "../../../config/contentHtml";

class Intro1 extends Component {
  render() {
    const title =
      this.props.lang === "en"
        ? "Introduce - Poinsettia Festival English"
        : "Giới thiệu cuộc thi - MC NHÍ TOÀN QUỐC 2023";

    return (
      <ContentLayout
        title={title}
        pageTitle={<FormattedMessage id="nav.intro" />}
        content={INTRO}
        setLang={this.props.setLang}
      ></ContentLayout>
    );
  }
}

export default Intro1;
