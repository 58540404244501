import $ from "jquery";
import React, { Component } from "react";
import { Helmet } from "react-helmet";
import Footer from "./Footer";
import Header from "./Header";

class BaseLayout extends Component {
  componentDidMount() {
    $(".section-banner")
      .find(".ImagesFrameCrop0")
      .each(function () {
        $(this).removeClass("wide");
        $(this).removeClass("tall");
        if (
          $(this).width() / $(this).height() >
          $(this).parent().width() / $(this).parent().height()
        ) {
          $(this).addClass("wide");
        } else {
          $(this).addClass("tall");
        }
      });
    $(".section-banner")
      .find(".ImagesFrameCrop0")
      .children("img")
      .each(function () {
        $(this).removeClass("wide");
        $(this).removeClass("tall");
        if (1396 / 770 > $(this).parent().width() / $(this).parent().height()) {
          $(this).addClass("wide");
        } else {
          $(this).addClass("tall");
        }
      });

    $(".social")
      .find(".link")
      .click(function () {
        $(this).next(".social-list").toggleClass("active");
      });

    $(".check").click(function () {
      $(this).toggleClass("active");
    });
  }

  render() {
    // const nofKey = Date.now().toString();

    return (
      <React.Fragment>
        <Header setLang={this.props.setLang} />
        <Helmet>
          <title>{this.props.title}</title>
          <meta
            name="description"
            content={
              this.props.description ||
              this.props.title ||
              "MC NHÍ TOÀN QUỐC 2023"
            }
          />
          <link
            rel="canonical"
            href={this.props.canonical || "https://mcnhi.bvote.vn"}
          />
          <meta
            name="twitter:card"
            content={
              this.props.description ||
              this.props.title ||
              "MC NHÍ TOÀN QUỐC 2023"
            }
          />
          <meta
            property="og:url"
            content={this.props.canonical || "https://mcnhi.bvote.vn"}
          />
          <meta
            property="og:title"
            content={this.props.title || "MC NHÍ TOÀN QUỐC 2023"}
          />
          <meta
            property="og:description"
            content={
              this.props.description ||
              this.props.title ||
              "MC NHÍ TOÀN QUỐC 2023"
            }
          />
        </Helmet>
        {this.props.children}
        <Footer />
      </React.Fragment>
    );
  }
}

export default BaseLayout;
