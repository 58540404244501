import React from "react";
import { injectIntl } from "react-intl";
import BaseLayout from "../../Layouts/BaseLayout";
import SectionBanner from "../Index/SectionBanner";
import SectionBrand from "../Index/SectionBrand";
import { ListSection } from "./List";

export const GroupScreen = (props) => {
  const title =
    props.lang === "en"
      ? "Home - Poinsettia Festival English"
      : "Trang chủ - MC NHÍ TOÀN QUỐC 2023";

  return (
    <BaseLayout title={title} description={title} setLang={props.setLang}>
      <main id="main">
        <SectionBanner />
        <ListSection />
        <SectionBrand />
      </main>
    </BaseLayout>
  );
};

export default injectIntl(GroupScreen);
